<template>
  <div class="pricingOrder">
    <div class="titleWrap">
      <div class="title">{{$t('buy.order_title')}}</div>
      <div v-if="(mode === 'promotion') && (userLanguage === 'jp')" class="subtitle">永久会員になって、中断のない英語学習を楽しもう！</div>
      <div v-else class="subtitle">{{$t('buy.order_subtitle')}}</div>
    </div>
    <div class="orderWrap">
      <div class="orderInfoWrap" v-if="orderInfo">
        <div class="orderSummary">{{$t('buy.order_summary')}}</div>
        <div class="subscriptionType">{{$t('buy.subscription_type')}} {{userSelectedPlanTrans}}</div>
        <div class="prices">{{$t('buy.price')}} <span class="pricesHighlight">{{userYourPriceTrans}}</span></div>
        <div class="divider"></div>
        <div class="orderInfoItem">
          <div class="key">{{$t('buy.account')}}</div>
          <div class="value">{{orderInfo.user_email}}</div>
        </div>
        <div class="orderInfoItem">
          <div class="key">{{$t('buy.sign_in_method')}}</div>
          <div class="value">{{getSignInMethodTrans()}}</div>
        </div>
        <template v-if="mode === 'normal'">
          <div v-if="isHaveFreeTrial" class="orderInfoItem">
            <div class="key">{{$t('buy.7_day_free_trial')}}</div>
            <div class="value">{{getFreeTrialTrans()}}</div>
          </div>
          <div v-else class="orderInfoItem">
            <div class="key">{{$t('buy.start_date')}}</div>
            <div class="value">{{getStartDateTrans()}}</div>
          </div>
          <div v-if="orderInfo.user_auto_renew" class="orderInfoItem">
            <div class="key">{{$t('buy.next_payment')}}</div>
            <div class="value">{{getNextPaymentTrans()}}</div>
          </div>
          <div class="divider"></div>
          <div class="returnButton" @click="returnHandle">
            <div class="returnText">{{$t('buy.choose_another_plan')}}</div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </template>
      </div>

      <div class="payWrap">
        <div class="selectPaymentMethod">{{$t('buy.select_payment_method')}}</div>
        <!-- <div class="payItem" :class="{active: payType === 'paypal'}" @click="payTypeHandle('paypal')">
          <div class="payType">PayPal</div>
          <img class="payImg" src="https://webjson.lingodeer.com/mediaSource/static/images/purchase/paypal.png" alt="paypal">
          <div class="select"><svg t="1673848549391" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3682" width="16" height="16"><path d="M416.832 798.08C400.64 798.08 384.512 791.872 372.16 779.52L119.424 525.76C94.784 500.992 94.784 460.8 119.424 436.032 144.128 411.264 184.128 411.264 208.768 436.032L416.832 644.928 814.4 245.76C839.04 220.928 879.04 220.928 903.744 245.76 928.384 270.528 928.384 310.656 903.744 335.424L461.504 779.52C449.152 791.872 432.96 798.08 416.832 798.08Z" fill="#272636" p-id="3683"></path></svg></div>
        </div> -->
        <div class="payItem" :class="{active: payType === 'card'}" @click="payTypeHandle('card')">
          <div class="payType">{{userLanguage === 'jp' ? 'クレジットカード' : 'Credit Card'}}</div>
          <img class="payImg" src="https://webjson.lingodeer.com/mediaSource/static/images/purchase/card.png" alt="paypal">
          <div class="select"><svg t="1673848549391" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3682" width="16" height="16"><path d="M416.832 798.08C400.64 798.08 384.512 791.872 372.16 779.52L119.424 525.76C94.784 500.992 94.784 460.8 119.424 436.032 144.128 411.264 184.128 411.264 208.768 436.032L416.832 644.928 814.4 245.76C839.04 220.928 879.04 220.928 903.744 245.76 928.384 270.528 928.384 310.656 903.744 335.424L461.504 779.52C449.152 791.872 432.96 798.08 416.832 798.08Z" fill="#272636" p-id="3683"></path></svg></div>
        </div>
        <div class="buttonWrap">
          <div v-show="payType === 'card'" class="checkoutButton" @click="stripeHandle">{{$t('buy.check_out')}}</div>
          <div v-show="payType === 'paypal'" id="paypal-button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { getPlans } from '@/api/order'
  import BASE_64 from '@/utils/encryption/base64/base64Util'

  export default {
    data() {
      return {
        timer: null,
        orderID: '',
        mode: 'normal',
        payType: 'card',
        activeList: ['time-limited-offer-2023-jp', 'blackfriday-2023-jp']
      }
    },
    inject: ['signIn'],
    props: ['orderInfo'],
    computed: {
      ...Vuex.mapGetters(['isHaveFreeTrial', 'productsInfo', 'userInfo', 'userLanguage']),
      paypalCreateSubscription() {
        let orderInfo = this.orderInfo
        return (data, actions) => {
          return actions.subscription.create({
            plan_id: orderInfo.paypal_planid,
            custom_id: orderInfo.user_uid,
            application_context: { custom: { token: orderInfo.token } }
          })
        }
      },
      paypalCreateOrder() {
        let orderInfo = this.orderInfo
        let planid = 'premium_lifetime_pr'
        if (this.userLanguage === 'jp') planid = 'premium_lifetime_pr_jp'
        return async () => {
          const response = await fetch(orderInfo.paypal_createbill_url, {
            method: "POST",
            headers: { "Content-Type": "text/plain" },
            body: BASE_64.encode(BASE_64.encode(`${planid}#${orderInfo.user_uid}`))
          })
          let orderID = await response.text()
          return orderID
        }
      },
      paypalOnApprove() {
        let that = this
        let orderInfo = this.orderInfo
        return async (data, actions) => {
          if (orderInfo.user_selected_plan.indexOf('Lifetime') >= 0) {
            const response = await fetch(orderInfo.paypal_executebill_url, {
              method: "POST",
              headers: { "Content-Type": "text/plain" },
              body: data
            })
            const details = await response.json()
            const errorDetail = Array.isArray(details.details) && details.details[0]
            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
              return actions.restart()
            }
          }
          that.$router.push({ name: 'PricingResult', param: { result: 'success', price: that.orderInfo.user_your_price }})
        }
      },
      paypalStyle() {
        return {
          shape: 'pill',
          color: 'gold',
          layout: 'horizontal',
          label: 'paypal',
          tagline: false
        }
      },
      userSelectedPlanTrans() {
        if (this.mode ==='normal') {
          if (this.orderInfo.user_selected_plan.indexOf('Six Years') >= 0) {
            return this.orderInfo.user_selected_plan
          } else {
            return this.$t(`buy.${this.orderInfo.user_selected_plan}`)
          }
        } else {
          if (this.userLanguage === 'jp') {
            return 'FluenDay永久会員一括購入プラン（プロモーション）'
          }
          return this.orderInfo.user_selected_plan
        }
      },
      userYourPriceTrans() {
        if (this.mode ==='normal') {
          return this.orderInfo.user_your_price
        } else {
          return this.orderInfo.user_your_price.replace('/year', '')
        }
      }
    },
    mounted() {
      if (this.activeList.includes(this.$route.params.type)) {
        // 促销购买
        let loading = this.$loading()
        this.mode = 'promotion'

        this.timer = setTimeout(() => {
          this.signIn()
        }, 2000);

        this.$watch('userInfo', async () => {
          clearTimeout(this.timer)
          let productsInfo = await getPlans(this.userInfo.UID)
          this.$store.commit('set_productsInfo', productsInfo)
          if (this.userLanguage === 'jp') {
            this.orderInfo = this.productsInfo.products.find(item => item.user_selected_plan === 'FluenDay Premium - Lifetime (Promotion) JP')
          } else {
            this.orderInfo = this.productsInfo.products.find(item => item.user_selected_plan === 'FluenDay Premium - Lifetime (Promotion)')
          }
          this.initPaypalSDK()
          loading.close()
        })
      } else if (!this.orderInfo || !this.productsInfo) {
        this.$router.push('/pricing')
      }
      // else {
      //   this.initPaypalSDK()
      // }
    },
    methods: {
      initPaypalSDK() {
        let script = document.createElement('script')
        let clientId = this.productsInfo.clientid
        if (this.orderInfo.user_selected_plan.indexOf('Lifetime') >= 0) {
          let currency = 'USD'
          if (this.userLanguage === 'jp') currency = 'JPY'
          script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&disable-funding=credit,paylater`
        } else {
          script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=true&intent=subscription&disable-funding=credit,paylater`
        }
        script.addEventListener('load', this.paypalSetLoaded)
        document.body.appendChild(script)
      },
      paypalSetLoaded() {
        let options = {
          style: this.paypalStyle,
          onApprove: this.paypalOnApprove
        }
        if (this.orderInfo.user_selected_plan.indexOf('Lifetime') >= 0) {
          options.createOrder = this.paypalCreateOrder
        } else {
          options.createSubscription = this.paypalCreateSubscription
        }
        window.paypal.Buttons(options).render('#paypal-button')
      },
      payTypeHandle(payType) {
        this.payType = payType
      },
      stripeHandle() {
        window.location.href = this.orderInfo.stripe_planid
      },
      returnHandle() {
        window.history.back()
      },
      getSignInMethodTrans() {
        let signInMethod = this.orderInfo.user_account_from
        if (signInMethod === 'em') {
          return this.$t('buy.Email')
        } else if (signInMethod === 'ai') {
          return this.$t('buy.Apple')
        } else if (signInMethod === 'gg') {
          return this.$t('buy.Google')
        } else if (signInMethod === 'fb') {
          return this.$t('buy.Facebook')
        }
      },
      getStartDateTrans() {
        let userStartDate = this.orderInfo.user_start_date
        let startDate = new Date(userStartDate)
        let startDateDay = startDate.getDate()
        let startDateMonth = startDate.getMonth() + 1
        let startDateYear = startDate.getFullYear()
        let startDateTrans =  this.$t(`buy.month_${startDateMonth}`).replace('%s', startDateDay) + ', ' + startDateYear
        return startDateTrans
      },
      getNextPaymentTrans() {
        let userAutoRenew = this.orderInfo.user_auto_renew
        let userStartDate = this.orderInfo.user_start_date
        let startDate = new Date(userStartDate).getTime()
        let nextPaymentDate
        let oneDay = 1000 * 3600 * 24
        let day7 = 1000 * 3600 * 24 * 7

        if (userAutoRenew === 'Every Year') {
          if (this.isHaveFreeTrial) {
            nextPaymentDate = new Date(startDate + day7 + oneDay * 365)
          } else {
            nextPaymentDate = new Date(startDate + oneDay * 365)
          }
        } else if (userAutoRenew === 'Every Month') {
          if (this.isHaveFreeTrial) {
            nextPaymentDate = new Date(startDate + day7 + oneDay * 30)
          } else {
            nextPaymentDate = new Date(startDate + oneDay * 30)
          }
        }
        let nextPaymentDateDay = nextPaymentDate.getDate()
        let nextPaymentDateMonth = nextPaymentDate.getMonth() + 1
        let nextPaymentDateYear = nextPaymentDate.getFullYear()
        let nextPaymentDateTrans =  this.$t(`buy.month_${nextPaymentDateMonth}`).replace('%s', nextPaymentDateDay) + ', ' + nextPaymentDateYear
        return nextPaymentDateTrans
      },
      getFreeTrialTrans() {
        let userStartDate = this.orderInfo.user_start_date
        let startDate = new Date(userStartDate).getTime()
        let day7 = 1000 * 3600 * 24 * 7
        let expireDate = new Date(startDate + day7)
        let expireDateDay = expireDate.getDate()
        let expireDateMonth = expireDate.getMonth() + 1
        let expireDateYear = expireDate.getFullYear()
        let expireDateTrans =  this.$t(`buy.month_${expireDateMonth}`).replace('%s', expireDateDay) + ', ' + expireDateYear
        let freeTrialTrans = this.$t('buy.no_charge_before_%d').replace('%d', expireDateTrans)
        return freeTrialTrans
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pricingOrder {
    .titleWrap {
      margin: 40px auto 0;
      color: #FFFFFF;
      text-align: center;
      .title {
        font-weight: 900;
        font-size: 30px;
      }
      .subtitle {
        margin-top: 10px;
        font-weight: 500;
        font-size: 22px;
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background: rgba(64,0,0,0.13);
      margin: 20px 0;
    }
    .orderInfoItem {
      display: flex;
      align-items: center;
      height: 30px;
      font-family: Helvetica;
      font-weight: 700;
      font-size: 12px;
      color: #09123B;
      .value {
        margin-left: 12px;
      }
    }
    .orderWrap {
      margin: 40px auto 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .orderInfoWrap {
        min-width: 420px;
        padding: 30px;
        border-radius: 6px;
        background: #FFFFFF;
        box-sizing: border-box;
        .orderSummary {
          margin-bottom: 40px;
          font-family: Helvetica;
          font-weight: 700;
          font-size: 20px;
          color: #09123B;
        }
        .subscriptionType, .prices {
          margin-top: 20px;
          font-family: Helvetica;
          font-weight: 700;
          font-size: 14px;
          color: #09123B;
        }
        .pricesHighlight {
          font-size: 18px;
          color: #EE5151;
        }
        .returnButton {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-style: italic;
          color: #666666;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
          .returnText {
            margin-right: 4px;
          }
        }
      }
      .payWrap {
        margin-left: 30px;
        width: 450px;
        padding: 30px;
        border-radius: 6px;
        background: #FFFFFF;
        box-sizing: border-box;
        .selectPaymentMethod {
          margin-bottom: 40px;
          font-family: Helvetica;
          font-weight: 700;
          font-size: 20px;
          color: #09123B;
        }
        &.paypalWrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .paypalInfoImg {
          width: 180px;
          margin-top: 20px;
        }
        .payItem {
          width: 100%;
          height: 72px;
          border: 2px solid rgba(64,0,0,0.13);
          border-radius: 12px;
          margin: 0 auto 30px;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .payType {
            width: 80px;
            font-family: Helvetica;
            font-weight: 700;
            font-size: 14px;
            color: #2E355A;
            white-space: nowrap;
          }
          .payImg {
            width: 111px;
          }
          .select {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background: #FFC700;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
          }
          &.active {
            border: 2px solid #FFC700;
            border-radius: 12px;
            .select {
              opacity: 1;
            }
          }
        }
        .buttonWrap {
          margin-top: 40px;
          width: 100%;
          height: 64px;
          .checkoutButton {
            width: 100%;
            height: 45px;
            line-height: 45px;
            border-radius: 45px;
            background: #FFC439;
            font-family: Helvetica;
            font-weight: 700;
            font-size: 16px;
            color: #000000;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .pricingOrder {
      .titleWrap {
        width: 90%;
        margin: 30px auto 0;
      }
      .orderWrap {
        margin: 20px auto 0;
        flex-direction: column;
        align-items: center;
        .orderInfoWrap {
          width: 90%;
          min-width: unset;
          padding: 20px;
        }
        .payWrap {
          margin-top: 20px;
          margin-bottom: 30px;
          margin-left: 0;
          width: 90%;
          padding: 20px;
        }
      }
    }
  }
</style>